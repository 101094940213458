<template>
  <div class="account-manage">
    <div class="report-title-box">账号管理</div>
    <div class="divider"></div>
    <div class="manage-main">
      <div class="account-box">
        <span>当前主账号：</span>
        <span class="phone">{{ dataPO.phone }}</span>
      </div>
      <div class="explain-box">
        <span class="iconfont icon-zhushibiaozhui"></span>
        <span>说明：每个会员主账号至多可开通{{ childAccountQty }}个子账号 </span>
      </div>
      <div class="add-button">
        <el-button type="primary" @click="addAccount">
          <i class="el-icon-plus"></i>
          <span style="margin-left: 8px">新增成员</span>
        </el-button>
      </div>
      <div v-if="!tableData" style="margin-top: 150px">
        <empty name="暂无数据"></empty>
      </div>
      <div class="table-box" v-if="tableData">
        <el-table
          :data="tableData"
          style="width: 100%"
          header-cell-class-name="table_header"
          height="400px"
        >
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="consumerName" label="姓名"></el-table-column>
          <el-table-column prop="childPost" label="职务"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column
            prop="updateDate"
            label="添加日期"
            width="220"
          ></el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <span class="untie" @click="toUntie(scope)">解绑</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <add-modal ref="addModal"></add-modal>
    <untie-modal ref="untieModal" :info="info"></untie-modal>
  </div>
</template>

<script>
import AddModal from "./addModal";
import UntieModal from "./untieModal";
import Empty from "@/components/Empty";
export default {
  components: {
    AddModal,
    UntieModal,
    Empty,
  },
  data() {
    return {
      tableData: [],
      dataPO: {},
      childAccountQty: "",
      info: {},
    };
  },
  created() {},
  mounted() {
    this.getInfo();
    this.getChildInfo();
    this.childAccountPage();
  },
  methods: {
    // 获取用户
    getInfo() {
      this.$api.personalCenter
        .getInfo()
        .then((res) => {
          this.dataPO = res.data;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 获取子账户信息
    getChildInfo() {
      this.$api.personalCenter
        .getChildInfo()
        .then((response) => {
          this.childAccountQty = response.data.childAccountQty;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 获取列表
    childAccountPage() {
      this.$api.personalCenter
        .childAccountPage()
        .then((res) => {
          this.tableData = res.rows;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 点击新增
    addAccount() {
      this.$refs.addModal.visible = true;
    },
    // 点击解绑
    toUntie(scope) {
      this.info = scope.row;
      this.$refs.untieModal.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
