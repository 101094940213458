<template>
  <div class="untie-modal">
    <Modal :visible.sync="visible">
      <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">提示</div>
      <div class="untie-box">
        <div class="hint-container">
          <div class="hint">解绑后会员权益将被收回，是否确认解绑该账号？</div>
          <div class="button-box">
            <el-button @click="toCancel">取消</el-button>
            <el-button type="primary" @click="confirmClick">确定</el-button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  components: {
    Modal,
  },
  props: ["info"],
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    // 点击取消
    toCancel() {
      this.visible = false;
    },
    // 点击确定
    confirmClick() {
      console.log(this.info);
      this.$api.personalCenter
        .unbundling({ childConsumerId: this.info.consumerId })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("子账号解绑成功");
            this.visible = false;
            this.$parent.childAccountPage();
          }
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
