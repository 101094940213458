<template>
  <div class="add-modal">
    <Modal :visible.sync="visible" @reset="resetFields">
      <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
        新增成员
      </div>
      <div class="add-form">
        <div class="form-container">
          <div class="form-box">
            <el-form ref="dataPO" :model="dataPO" :rules="rules">
              <!-- <el-form-item prop="childName" label="姓名">
                <el-input
                  v-model="dataPO.childName"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item> -->
              <el-form-item prop="childPost" label="职务">
                <el-input
                  v-model="dataPO.childPost"
                  maxlength="200"
                  placeholder="请输入职务（非必填）"
                ></el-input>
              </el-form-item>
              <el-form-item prop="account" label="手机号">
                <el-input
                  v-model="dataPO.account"
                  placeholder="请输入手机号"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @blur="dataPO.account = $event.target.value"
                  @change="handleInputPhone"
                ></el-input>
              </el-form-item>
              <el-form-item prop="code" label="验证码">
                <div class="code-form-box">
                  <el-input
                    class="code-form"
                    v-model="dataPO.code"
                    placeholder="请输入验证码"
                  ></el-input>
                  <div class="get-code" @click.prevent="getCode">
                    {{ codeText }}
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="button-box">
            <el-button type="primary" @click="confirmAdd" :disabled="disabled"
              >确认添加</el-button
            >
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  components: {
    Modal,
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      const reg =
        "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
      if (new RegExp(reg).test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
    };
    return {
      visible: false,
      dataPO: {
        childName: "",
        childPost: "",
        account: "", //手机号
        code: "", // 验证码
      },
      phone: "",
      disabled: false,
      rules: {
        // childName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        account: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          { validator: checkPhone, trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      codeText: "获取验证码",
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    // 获取用户
    getInfo() {
      this.$api.personalCenter
        .getInfo()
        .then((res) => {
          this.phone = res.data.phone;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 获取验证码
    getCode() {
      if (this.codeText !== "获取验证码") {
        return;
      }
      this.$refs.dataPO.validateField("account", (errorMessage) => {
        if (!errorMessage) {
          this.$api.user
            .getVerificationCode({ phone: this.dataPO.account })
            .then(() => {
              this.$message.success("已发送，请注意查收");
              let maxNum = 59;
              let oldCodeText = this.codeText;
              this.codeText = `${maxNum + 1}s重新发送`; //初始显示倒计时
              let codeCountDown = setInterval(() => {
                let countDownNum = maxNum--;
                this.codeText = `${countDownNum}s重新发送`;
                if (countDownNum == 0) {
                  //倒计时结束
                  this.codeText = oldCodeText;
                  clearInterval(codeCountDown);
                }
              }, 1000);
            })
            .catch((err) => {
              this.$message.error(err?.msg);
            });
        }
      });
    },
    //
    handleInputPhone(val) {
      if (val == this.phone) {
        this.$message.error("新增账号不能为自身账号！");
        this.disabled = true;
        return;
      } else {
        this.disabled = false;
        return;
      }
    },
    // 点击确认添加
    confirmAdd() {
      this.$refs.dataPO.validate((valid) => {
        if (valid) {
          this.$api.personalCenter
            .addChildAccount(this.dataPO)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success("子账号添加成功");
                // clearInterval(codeCountDown);
                this.codeText = "获取验证码";
                this.visible = false;
                this.$refs.dataPO.resetFields();
                this.$parent.childAccountPage();
              }
            })
            .catch((err) => {
              this.$message.error(err?.msg);
              // clearInterval(codeCountDown);
              this.codeText = "获取验证码";
            });
        } else {
          return false;
        }
      });
    },
    // 关闭弹窗时重置表单
    resetFields() {
      this.$refs.dataPO.resetFields();
      // clearInterval(codeCountDown);
      this.codeText = "获取验证码";
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
